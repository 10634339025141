@import url('https://fonts.googleapis.com/css?family=Inter:slnt,wght@-10..0,100..900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Allura&display=swap');

* {
  font-family: 'Inter';
  margin: 0;
  text-decoration: none;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

.center {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
}

.center>* {
  display: block;
  margin: auto;
  text-align: center;
}

.verticalCenter {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.noSelect {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input,
textarea {
  outline: none;
  resize: none;
  font-family: "Roboto";
  border: none;
  -moz-appearance: textfield; 
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: 0px;
  font-family: 'Roboto';
  text-align: center;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(226, 232, 240);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(67, 220, 128);
  border-radius: 10px;
}

/* For Firefox */
scrollbar-color: rgb(67, 220, 128) rgb(226, 232, 240);
scrollbar-width: thin;

/* For Edge and other browsers using the new standard */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(67, 220, 128) rgb(226, 232, 240);
}

.noScroll {
  overflow: auto;
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  scrollbar-width: none;  /* For Firefox */
}

.noScroll::-webkit-scrollbar {
  display: none;  /* For Chrome, Safari, and Opera */
}
